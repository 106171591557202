.parallax {
    position:relative;
    height: 300px;
    background-attachment: fixed;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/images/blueprint.png);
    z-index:99998;
    margin-top:0rem;
}
.parallax:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.legend{
    font-family:"Tech", sans-serif;
    color:#212121;
    font-size: .85rem;
    margin: 1rem auto;
    justify-self: center;
    width:auto !important;
}
.legend h1{
    font-family:"Orbitron", sans-serif !important;
    font-size: 1.5rem;
    text-transform: capitalize;
}

.hero{
    background-image: url(../../assets/images/outline.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    width:600px;
    height:600px;
}

.container{
    margin-top:3rem;
}

.blueprint{
    position:relative;
    /*background-color:rgba(13,71,161,.85)/*#01579b*/;
    background-image: linear-gradient(rgba(255,255,255,.4) 2px, transparent 2px),
    linear-gradient(90deg, rgba(255,255,255,.4) 2px, transparent 1px),
    linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255,255,255,.2) 1px, transparent 1px);
    background-size:100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px;
    /*min-height:calc(100vh - 150px);*/
}
@media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
    }
}