.label span{
    font-family: 'Tech',sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.image{
    display: block;
    width:300px !important;
    height:auto;
    margin:0 auto !important;
}

h2.title{
    font-family:"Primal", sans-serif !important;
    color:#212121;
    text-transform: uppercase;
    font-size:1.5rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-align: left;
    padding:0;
    padding-top:1rem;
    border-bottom: 0;
}

.description{
    font-size:.75rem !important;
    padding:2rem !important;
    border-bottom: 0px solid rgba(255,255,255,.15);
    background-color:rgba(255,255,255,.5);
    color:#212121;
}

.selected{
    background-color:rgba(0,0,0,.5) !important;
    color:#fafafa !important;
}