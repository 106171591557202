@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: scale(0) translate3d(100%, 100%, 0);
    }
    50% {
        opacity: 0.05;
        transform: scale(.8) translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@keyframes backInUp {
  0% {
    filter: blur(50px) grayscale(1);
    transform: translateY(1200px) scale(0);
    opacity: 0.1;
  }

  80% {
    filter: blur(10px) grayscale(1);
    transform: translateY(0px) scale(0.1);
    opacity: 1;
  }

  100% {
    filter: blur(0) grayscale(0);
    transform: scale(1);
    opacity: 1;
  }
}


@media only screen and (min-width: 992px){
    .xmasonry .xblock:first-of-type {
        animation: backInUp ease 0.15s;
        animation-iteration-count: 1;
    }
    .xmasonry .xblock:nth-of-type(2) {
        animation: backInUp ease .35s;
        animation-iteration-count: 1;
    }
    .xmasonry .xblock:nth-of-type(3) {
        animation: backInUp ease .5s;
        animation-iteration-count: 1;
    }
    .xmasonry .xblock:nth-of-type(4) {
        animation: backInUp ease 1s;
        animation-iteration-count: 1;
    }
    .xmasonry .xblock:nth-of-type(5) {
        animation: backInUp ease .25s;
        animation-iteration-count: 1;
    }
    .xmasonry .xblock:nth-of-type(6) {
        animation: backInUp ease 1s;
        animation-iteration-count: 1;
    }
}