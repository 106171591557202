
@font-face{
    font-family:"Tech";
    src:url("../../../../assets/webfonts/ShareTechMono-Regular.ttf");
    font-weight:400;
    font-display:swap;
}
.console-wrapper{
    display: flex;
    flex-direction: column;
    position:relative;
    font-family: 'Tech', monospace;
    font-size: .75rem;
    color: #e0e0e0;
    width:100%;
    text-align:right;
    flex:1;
    height:100vh;
    background-color:#000 !important;
    background-image: unset !important;
    padding-left:0rem;
    overflow-x: hidden;
}

.wrapper{
    position:absolute;
    top:0;
    left:1rem;
    width:100%;
    background-color:rgba(0,0,0,1);
    height:100vh;
    z-index:-1;
}
/*
.console-wrapper:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left:0;
    bottom: 0;
    right: 0%;
    width: 16px;
    background-image: url(../../../../assets/images/side-border.svg);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 100% auto;
    z-index: 1;
}
*/
.console-wrapper > div:not(.wrapper){
    margin-top:auto;
    width:100%;
    align-self: flex-end;
    height:auto;
    background-color:rgba(0,0,0,1);
    padding:1rem 1rem 1rem 1rem;
}

.console-wrapper h1.console-title{
    font-family: 'Garde', sans-serif;
    margin:0;
    padding:.5rem;
    font-size:1rem;
    background:rgba(0,0,0,.5);
    min-height:2.5rem;
}

.console-wrapper .title{
    display:block;
    position:relative;
    text-transform: uppercase;
    font-weight: 600;
    z-index: 99995;
}
.console-wrapper span,
.console-wrapper p{
    line-height:1rem;
    padding:0;
    margin:0;
    cursor:default;
    color: #e0e0e0;
}

.console-wrapper p.line-wrapper:before {
    content:"$";
    margin-right:5px;
}
.console-wrapper p.line-wrapper {
    display:inline-block;
}

.console-wrapper a{
    color:#e0e0e0 !important;
    text-decoration: none;
    transition: color .2s ease-out;
}
.console-wrapper a:hover{
    color:rgb(230,174,6) !important;
}

.console-wrapper p:hover:not(.small,.divider,.title){
    color:#bdbdbd;
}
.console-wrapper p.small{
    white-space: nowrap;
    font-family: monospace !important;
    font-size: .2rem;
    line-height: .2rem;
}
.console-wrapper p.divider{
    margin-bottom:.5rem;
    width:100%;
    height:1rem;
}
.console-wrapper p.divider:before{
    content:'------------';
}
.console-wrapper p.small.divider:before{
    content:'';
}

.menu-button{
    font-family: 'Tech', monospace !important;
    font-size:.65rem !important;
    color:#424242 !important;
    display:none !important;
    width:48px;
    height:48px;
}
.menu-button i{
    font-size:1.5rem;
}

.terminal-wrapper{
    margin-top:1rem;
    min-height: 100px!important;
}

.terminal-wrapper,
.terminal{
    background:transparent !important;
    font-family: 'Tech',monospace !important;
    font-size:.75rem !important;
    color:#e0e0e0 !important;
    padding:0 !important;
}
.terminal div,
.terminal-wrapper div,
.terminal span,
.terminal-wrapper span,
.terminal p,
.terminal-wrapper p{
    background:transparent !important;
    font-family: 'Tech',monospace !important;
    font-size:.75rem !important;
    color:#e0e0e0 !important;
    padding:0 !important;
    line-height: 1rem !important;
    word-wrap: break-word !important;
}
.terminal-input input{
    height:1rem !important;
    caret-color: #DA338B !important;
}
.terminal-prompt{
    color:#e0e0e0 !important;
    font-size: .75rem !important;
    font-family: 'Tech', monospace !important;
}
.terminal-input{
    margin-top: .5rem !important;
    align-items: center !important;
    justify-content: center !important;
}

@media only screen and (max-width: 1200px){
    .menu-button{
        display:block !important;
    }
}