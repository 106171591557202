.parallax {
    position:relative;
    height: 300px;
    background-attachment: fixed;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/images/blueprint.png);
    z-index:99998;
    margin-top:2rem;
}
.parallax:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.hero-na{
    background-image: url(../../assets/images/faction-na.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    width:400px;
    height:400px;
}

.container{
    margin-top:3rem;
}

.container img{
    width:100%;
    height:auto;
}
.container p{
    font-family: 'Tech',sans-serif;
    font-size: 1rem;
    text-justify:distribute;
}


h1.title{
    font-family:"Primal", sans-serif !important;
    color:#212121;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 400;
    text-align: left;
    /*border-bottom: 1px solid rgba(0,0,0,.85);*/
    padding:1rem;
}

.dashes{
    color:rgba(255,255,255,.35);
}
/*
.description{
    padding-left:230px;
}
*/
.description > div:first-of-type{
    padding:0 1rem 1rem 1rem;
    box-sizing: border-box;
}


.gallery-wrapper{
    padding:1rem;
}

.hero{
    background-image: url(../../assets/images/pink-animated.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width:400px;
    height:300px;
}

@media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
    }
}

@media only screen and (max-width: 991px) {
    h1.title {
      display:none !important;
    }
    .description{
        padding-left:0;
    }
    .description > div:first-of-type{
        padding:0 2rem;
    }
}
