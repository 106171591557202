.container{
    margin-top:3rem;
}

.container img{
    width:100%;
    height:auto;
}
.container p{
    font-family: 'Tech',sans-serif;
    font-size: 1rem;
    text-justify:distribute;
}

.list{
    font-family: 'Tech',sans-serif;
    list-style: none;
    padding:0;
}

.small{
    font-size:.75rem !important;
}

.asterisk{
    color:#d32f2f;
}

h1.title{
    font-family:"Primal", sans-serif !important;
    color:#212121;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 400;
}

.tl-content{
    display: flex;
    flex-direction: column;
    padding:1rem !important;
    justify-content: center
}

.blur{
    filter: blur(5px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bgcolor{
    background-color:#40A7AC !important;
}

.bordercolor{
    border-color:#40A7AC !important;
}
.dashes{
    color:rgba(255,255,255,.35);
}

@media only screen and (max-device-width: 991px) {
    h1.title{
        width:100%;
        font-size:1.25rem;
    }
}

