.xp-table{
    font-family: 'Tech',sans-serif;
    max-width:35%;
    margin:1rem auto;
    margin-right:2rem;
    background-color: rgba(255,255,255,.5) !important;
    border-radius: 0 !important;
    overflow:hidden !important;
}

.xp-table td,
.xp-table th{
    font-family: 'Tech',sans-serif;
    /*font-size: 1.25rem !important;*/
    text-align: right;
    text-transform: uppercase;
    font-weight:500;
    color:#212121;
    border-bottom-color: #e0e0e0 !important;
}

.xp-table th{
    background-color:rgba(0,0,0,.5);
    color:#fafafa;
}
.pagination p,
.pagination button{
    font-size:.85rem !important;
    color:#424242 !important;
}