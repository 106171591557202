.content-box,
.tile-box{
    min-height:250px;
    min-width:350px;
    width:100%;
    justify-self: center;
    align-self: center;
}
.tile-wrapper{
    display:flex;
    flex-direction: column;
    position:relative;
    margin:0;
    z-index:99999;
    height:calc(100vh - 180px);
    width:100;
}

.content-box{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:507px; /*top and bottom tiles + 6px gap in each*/
    background-color: #212121 !important;
    z-index:999998;
    display:none;
}

.content-box.show{
    display:flex;
}

.home-container{
    position:relative;
    display:flex !important;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    min-height: calc(100vh - 90px);
}

.hound-wrapper{
    display:none !important;
}

@media only screen and (min-width: 992px){
    .hound-wrapper{
        display:block !important;
    }
    .tile-wrapper{
        padding-left:6rem !important;
        padding-right:2rem !important;
        height:unset !important
    }
    .content-box{
        top:16px;
        left:calc(6rem + 3px);
        width:calc(100%  - 8rem - 12px);
    }
}