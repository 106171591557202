
.header-bar{
    margin-bottom:0;
    background: transparent !important;
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)) !important;*/
    color:inherit;
    z-index:1100 !important;
    padding:0 !important;
}

.header-bar-container{
    display:flex;
    align-items: center;
    padding:.5rem 0 .5rem 0;
    color:#212121;
}

.header-bar label{
    width: 100%;
    margin-right:2rem;
    text-align: right;
    font-size:.75rem;
    line-height: 1.25rem;
}

.header-bar .logo{
    display:flex;
    align-items: center;
    font-family: 'Mechsuit', sans-serif;
    /*font-family: 'Press Start 2P', cursive;*/
    font-weight:900;
    /*font-size:1rem;*/
    font-size:1rem;
    color:#212121;
    text-decoration:none;
    align-items: center;
    margin:0 !important;
    width:220px;
}

.header-bar .logo h1{
    font-weight: 600;
    font-size:1.25rem;
    color:#eee;
    margin:auto 0;
}

.header-bar .logo div {
    display:inline-flex;
    margin: auto 0;
    /*margin-right:.5rem;*/
    height:60px;
    width:auto;
    /*background:url(../../../assets/images/logo-bg.png) no-repeat center left;*/
    filter: grayscale(0);
    background-size:contain;
    vertical-align:middle;
    justify-content: center;
    align-items: center;
    padding-left:.6rem;
}

.header-bar .logo span {
/*-webkit-text-stroke: 1px #006064;
color: #eee;
text-shadow:2px 2px 6px #00bcd4,
  -1px -1px 0 #00363a,  
   1px -1px 0 #00363a,
   -1px 1px 0 #00363a,
    1px 1px 0 #00363a;
*/
text-shadow:2px 2px 6px #757575,
  -1px -1px 0 #eee,  
   1px -1px 0 #eee,
   -1px 1px 0 #eee,
    1px 1px 0 #eee;
    font-family: 'Mandatory', sans-serif !important;
    color: #212121;
    font-size: 2rem; /*1.7rem*/
}


.header-bar .logo img{
    display:block;
    height:40px;
    margin:0;
}

.header-bar a.logo:hover{
    color:inherit;
}

.header-bar .tabs{
    margin-left:auto;
}

.header-bar .indicator{
    background:#f9f9f9;
}

.level-cyan,
.level{
    background-color: #bdbdbd !important;
    height:2px !important;
    margin-top: .6rem !important;
    width:200px !important;
    margin-left:auto !important;
}
.level-cyan span,
.level span{
    background-color: #DA338B !important;
    animation:expand 10s linear;
}

.level-cyan span{
    background-color: #3EA6AB !important;
}


@keyframes expand {
    0% {
      width:0;
    }
    49% {
        width:0;
    }
    100% {
      width:100%;
    }
  }