.container{
    margin-top:3rem;
}

.container img{
    width:100%;
    height:auto;
}
.container p{
    font-family: 'Tech',sans-serif;
    font-size: 1rem;
    text-justify:distribute;
}


h2.title,
h1.title{
    font-family:"Primal", sans-serif !important;
    color:#212121;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 400;
    text-align:left;
    border-bottom: 0px solid rgba(255,255,255,.15);
    padding:1rem;
}

h2.title{
    padding:0;
    padding-top:1rem;
    border-bottom: 0;
    font-size:1.25rem;
    line-height: 1.5rem;
}

.dashes{
    color:rgba(255,255,255,.35);
}
/*
.description{
    padding-left:230px;
}
*/
.description > div:first-of-type{
    padding:0 1rem 1rem 1rem;
    box-sizing: border-box;
}

.gallery-wrapper{
    padding:.5rem;
}


@media only screen and (max-width: 1200px) {
    h1.title {
      display:none !important;
    }
    .description{
        padding-left:0;
    }
    .description > div:first-of-type{
        padding:0 2rem;
    }
}

