.part{
    position:absolute;
    cursor:pointer;
    transition: all 1s ease;
    overflow: visible;
}

.part path{
    fill:#757575;
    opacity:1;
    /*transition: all .15s ease-in-out;*/
}
.part.join:hover path,
.part:hover path{
    fill:#424242 !important;
    opacity:1;
}

.part.active path{
    fill:#424242;
    opacity:1;
}
.part.dim:hover path,
.part.active:hover path{
    fill:#9e9e9e !important;
    opacity:1;
}

.part.dim path{
    fill:#bdbdbd;
    opacity:1;
}

.part.join{
    animation:none;
    width:390px;
    height:290px;
}

.part.head{
    top:9%;
    right: 6%;
    width:19%;
    height:auto;
    z-index:99998;
}
.part.join.head{
    top:5%;
    right: 0;
}

.part.ear{
    top:0; 
    right:24%;
    width:9%;
    height:auto;
    z-index:99999;
}
.part.join.ear{
    top:0; 
    right: 10.8%;
}

.part.torso{
    top:10%; 
    right:31%;
    width:44.5%;
    height:auto;
    z-index:99998;
}
.part.join.torso{
    top:12.2%; 
    right: 35%;
}
.part.active.torso{
    right:5%;
    width:80%;
}

.part.neck{
    top:29%;
    right: 10%;
    width:20.5%;
    height:auto;
    z-index:99997;
}
.part.join.neck{
    top:6%; 
    right: 15%;
}

.part.ass{
    top:9%; 
    right:76%;
    width:10%;
    height:auto;
}
.part.join.ass{
    top:12.5%; 
    right:71.5%;
}

.part.tail-1{
    top:1%; 
    right:79%;
    width:14%;
    height:auto;
}
.part.join.tail-1{
    top:14.3%; 
    right: 76.7%;
}
.part.active.tail-1{
    top:0.5%;
    right:8%;
    width:20%;
}

.part.tail-2{
    top:11%;
    right: 92%;
    width:4.7%;
    height:auto;
}
.part.join.tail-2{
    top:21.2%;
    right: 89.2%;
}
.part.active.tail-2{
    top:10%;
    right:25%;
    width:10%;
}

.part.tail-3{
    top:22%;
    right: 86%;
    width:3.7%;
    height:auto;
}
.part.join.tail-3{
    top:32%; 
    right: 88.7%;
}
.part.active.tail-3{
    top:32.5%;
    right:22.5%;
    width:10%;
}

.part.tail-4{
    top:32%; 
    right: 88%;
    width:6.5%;
    height:auto;
}
.part.join.tail-4{
    top:40.5%; 
    right: 88.6%;
}
.part.active.tail-4{
    top:55%;
    right:22%;
    width:15%;
}

.part.tail-5{
    top:37%; 
    right: 94.2%;
    width:5.4%;
    height:auto;
}
.part.join.tail-5{
    top:43%; 
    right:94.2%;
}
.part.active.tail-5{
    top:59%;
    right:35%;
    width:13%;
}
.part.rear-front-leg-top{
    top:38%; 
    right:70%;
    width:20%;
    height:auto;
    z-index:99999;
}
.part.join.rear-front-leg-top{
    top:19%; 
    right:74.5%;
}
.part.active.rear-front-leg-top{
    width:35%;
    right:50%;
}

.part.rear-front-leg-middle{
    top:64%;
    right:77%;
    width:22%;
    height:auto;
    z-index:99998;
}
.part.join.rear-front-leg-middle{
    top:39.2%; 
    right: 75.2%;
}
.part.active.rear-front-leg-middle{
    width:25%;
    right:75%;
}

.part.rear-front-leg-bottom{
    top:82%;
    right:80%;
    width:8.7%;
    height:auto;
    z-index:99998;
}
.part.join.rear-front-leg-bottom{
    top:53%; 
    right:90%;
}
.part.active.rear-front-leg-bottom{
    width:20%;
    right:75%;
}

.part.rear-front-leg-foot{
    top:90%;
    right:90%;
    width:14.8%;
    height:auto;
    z-index:99998;
}
.part.join.rear-front-leg-foot{
    top:68.3%;
    right:84.3%;
}
.part.active.rear-front-leg-foot{
    top:17%;
    width:20%;
    right: 75%;
}

.part.rear-back-leg-top{
    top:38%; 
    right: 48%;
    width:21%;
    height:auto;
    z-index:99997;
}
.part.join.rear-back-leg-top{
    top:17.5%; 
    right: 68.7%;
}
.part.active.rear-back-leg-top{
    width:35%;
    right:5%;
}

.part.rear-back-leg-middle{
    top:64%; 
    right:56%;
    width:23%;
    height:auto;
    z-index:99997;
}
.part.join.rear-back-leg-middle{
    top:38.2%; 
    right: 70%;
}
.part.active.rear-back-leg-middle{
    width:25%;
    right:50%;
}

.part.rear-back-leg-bottom{
    top:82%; 
    right:56%;
    width:8.7%;
    height:auto;
    z-index:99997;
}
.part.join.rear-back-leg-bottom{
    top:53.5%; 
    right:84.7%;
}
.part.active.rear-back-leg-bottom{
    width:20%;
    right:50%;
}

.part.rear-back-leg-foot{
    top:90%;
    right:65%;
    width:14.6%;
    height:auto;
    z-index:99997;
}
.part.join.rear-back-leg-foot{
    top:67.8%;
    right: 79.3%;
}
.part.active.rear-back-leg-foot{
    top:17%;
    width:20%;
    right: 50%;
}

.part.front-leg-top{
    top:45%; 
    right: 29%;
    width:14%;
    height:auto;
    z-index:99999;
}
.part.join.front-leg-top{
    top:15.3%; 
    right: 29%;
}
.part.active.front-leg-top{
    width:35%;
    right:50%;
}

.part.front-leg-middle{
    top:63%; 
    right:34%;
    width:15%;
    height:auto;
    z-index:99998;
}
.part.join.front-leg-middle{
    top:30%;
    right:27.5%;
}
.part.active.front-leg-middle{
    width:23%;
    right:25%;
}

.part.front-leg-bottom{
    top:70%;
    right:28%;
    width:11.5%;
    height:auto;
    z-index:99998;
}
.part.join.front-leg-bottom{
    top:43%;
    right:35.5%;
}
.part.active.front-leg-bottom{
    width:20%;
    right:25%;
}

.part.front-leg-foot{
    top:83%;
    right: 40%;
    width:15.2%;
    height:auto;
    z-index:99998;
}
.part.join.front-leg-foot{
    top:62%;
    right:31.5%;
}
.part.active.front-leg-foot{
    width:20%;
    right: 25%;
}

.part.back-leg-top{
    top:45%; 
    right: 5%;
    width:13%;
    height:auto;
    z-index:99996;
}
.part.join.back-leg-top{
    top:15.8%; 
    right:25.8%;
}
.part.active.back-leg-top{
    width:34%;
    right:5%;
}

.part.back-leg-middle{
    top:63%;
    right:10%;
    width:15%;
    height:auto;
    z-index:99996;
}
.part.join.back-leg-middle{
    top:29%;
    right:24.5%;
}
.part.active.back-leg-middle{
    width:23%;
    right:2%;
}

.part.back-leg-bottom{
    top:70%;
    right:4%;
    width:11%;
    height:auto;
}
.part.join.back-leg-bottom{
    top:43%;
    right:32.5%;
}
.part.active.back-leg-bottom{
    width:20%;
    right:1%;
}

.part.back-leg-foot{
    top:83%;
    right:15%;
    width:15%;
    height:auto;
    z-index:99997;
}
.part.join.back-leg-foot{
    top:62%;
    right: 28.3%;
}
.part.active.back-leg-foot{
    width:20%;
    right: 1%;
}

.part.active{
    top:10%;
    right:25%;
    width:50%;
    height:auto;
    z-index:9999999;
}