.drawer{
    background:transparent !important;
    background-image: unset !important;
    box-shadow: none !important;
    border:0 !important;
    overflow: hidden !important;
    transition: all .2s ease-in-out;
}
.menu{
    /*position:fixed !important;*/
    top:200px !important;
    left:0;
    padding:0 !important;
    margin:0 !important;
    border-right: 0px solid rgba(0,0,0,.85);
    /*background:rgba(255,255,255,.02);*/
    background-color: rgba(0, 0, 0,1);
    width:100%;
    max-width: 200px;
    z-index: 999;
}
.menu:before {
    content:'';
    position:absolute;
    top:0;
    right:0;
    width:20%;
    height:1px;
    border-bottom: 0px solid rgba(0,0,0,.85);
}
.menu:after {
    content:'';
    position:absolute;
    bottom:0;
    right:0;
    width:20%;
    height:1px;
    border-bottom: 0px solid rgba(0,0,0,.85);
}

.menu > div{
    transition: .25s all ease-out;
}
.menu > div:hover{
    background-color:rgba(255,255,255,.1)
}
.menu > div:hover span{
    color:#fff;
}

.menu p{
    font-family:"Primal", sans-serif !important;
    text-transform: uppercase;
    /*-webkit-text-stroke: 1px #121212;*/
    font-size: .6rem !important;
    font-weight: 300;
    text-align: right;
}

.menu span{
    font-family:"Primal", sans-serif !important;
    color:#e0e0e0;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.15rem;
    font-weight: 400;
    text-align: right;
}

.selected{
    color:#000 !important;
    background-color:rgb(230,174,6) !important;
}
.selected > div span,
.selected > div p{
    color:#000 !important;
}


.menu-container ul{
    width:100% !important;
    background-color: rgba(0,0,0,.15);
    margin-left:4px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.menu-container li{
    display:flex;
    flex-direction: column;
    font-family: 'Primal',sans-serif !important;
    font-size: 1rem;
    align-items: flex-start !important;
    justify-content: center !important;
    width:300px !important;
}

span.small{
    font-size: .65rem !important;
    font-weight: 300;
    color:rgba(255, 255, 255, 0.7) !important;
    -webkit-text-stroke: 1px #121212;

}

.menu-button{
    font-family: 'Primal',sans-serif !important;
    width:90% !important;
    margin:2rem auto 1rem auto !important;
    display: flex !important;
    border-top:0 !important;
    border-right:0 !important;
    border-left:0 !important;
    border-radius: 0 !important;
    border-color: rgba(0,0,0,.25) !important;
    background:rgba(255,255,255,.25) !important;
    color:#424242 !important;
    padding:1rem !important;
}

.menu-button i.icon{
    font-size: .75rem;
    text-align: right;
}