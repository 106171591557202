.breadcrumbs{
    font-family:"Primal", sans-serif !important;
    text-transform: uppercase;
    font-weight: 300;
    color:#f5f5f5 !important;
    /*-webkit-text-stroke: 1px #121212;*/
    font-size:.85rem !important;
    width:fit-content;
    margin-left:auto !important;
    margin-right:0rem !important;
    z-index: 9999;
    background-color: rgba(0, 0, 0,1);
}

.breadcrumbs li.separator{
    font-family:"Primal", sans-serif !important;
    -webkit-text-stroke: 0 !important;
    padding:0;
    margin:0;
    color:rgb(230,174,6);
}

.breadcrumbs a{
    font-family:"Primal", sans-serif !important;
    /*-webkit-text-stroke: 1px #121212;*/
    color:inherit;
    text-decoration: none;
    text-transform: uppercase !important;
}

.breadcrumbs a:hover{
    color:rgb(230,174,6);
    text-decoration: none !important;
}

.menu-container ul{
    width:100% !important;
    background-color: rgba(0,0,0,.85);
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding:0 !important;
}
.menu-container li{
    display:flex;
    flex-direction: column;
    font-family: 'Primal',sans-serif !important;
    font-size: .875rem;
    align-items: flex-start !important;
    justify-content: center !important;
    /*-webkit-text-stroke: 1px #282828;*/
    text-transform: uppercase !important;
}
.menu-container li:hover{
    background-color:rgba(255,255,255,.25) !important;
}