.container{
    font-family: 'Tech', sans-serif;
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
    color:#212121 !important;
    margin-top:3rem;
}

.container p{
    font-family: 'Tech',sans-serif;
    font-size: 1rem;
    text-justify:distribute;
}

h1.title{
    font-family:"Primal", sans-serif !important;
    color:#212121;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 400;
}

.dashes{
    color:rgba(255,255,255,.35);
}

@media only screen and (max-device-width: 991px) {
    h1.title{
        width:100%;
        font-size:1.25rem;
    }
}