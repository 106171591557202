@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');


.tip{
    /*font-family: 'Square Peg',cursive;*/
    color:#212121;
    width:auto;
    padding:0 2rem
}
.tip h1{
    margin-top:3rem;
    font-size: 2rem;
    line-height: 2.5rem;    
    text-transform: capitalize;
}

.tip p{
    font-family:"Tech", sans-serif;
    font-size:.85rem;
    max-width:250px;
    word-wrap: break-word;
}

.tip a{
    color:#d32f2f;
    text-decoration: none;
}
.tip a:hover{
    color:#e53935;
}


.hound{
    position:relative;
    aspect-ratio: 1 / 1 !important;
    margin:3rem 5rem;
}

.hound div{
    position:absolute;
    cursor:pointer;
    transition: all 1s ease;
}

.hound .hero{
    display:none;
}

.hound.join .hero{
    display:block;
    opacity:1;
    background-image: url(../../../assets/images/invis.png);
    background-repeat: no-repeat;
    background-size: contain;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    right:0;
    animation: fade-in 1.25s;
}


.hound.animate svg:nth-child(1),
.hound.animate svg:nth-child(6),
.hound.animate svg:nth-child(11),
.hound.animate svg:nth-child(14),
.hound.animate svg:nth-child(27){
    animation:floating infinite 3.5s ease-in-out;
}
.hound.animate svg:nth-child(2),
.hound.animate svg:nth-child(7),
.hound.animate svg:nth-child(15),
.hound.animate svg:nth-child(17),
.hound.animate svg:nth-child(21),
.hound.animate svg:nth-child(25){
    animation:floating-2 infinite 5s ease-in-out;
}
.hound.animate svg:nth-child(3),
.hound.animate svg:nth-child(8),
.hound.animate svg:nth-child(13),
.hound.animate svg:nth-child(16),
.hound.animate svg:nth-child(22){
    animation:floating-3 infinite 5.5s ease-in-out;
}
.hound.animate svg:nth-child(4),
.hound.animate svg:nth-child(9),
.hound.animate svg:nth-child(12),
.hound.animate svg:nth-child(19),
.hound.animate svg:nth-child(23),
.hound.animate svg:nth-child(26){
    animation:floating-4 infinite 4.5s ease-in-out;
}
.hound.animate svg:nth-child(5),
.hound.animate svg:nth-child(10),
.hound.animate svg:nth-child(18),
.hound.animate svg:nth-child(20),
.hound.animate svg:nth-child(24){
    animation:floating-5 infinite 4s ease-in-out;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(-1px, 0.5px); }
    100%   { transform: translate(0, 0px); }   
}
@keyframes floating-2 {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(1px, 1.5px); }
    100%   { transform: translate(0, 0px); }   
}
@keyframes floating-3 {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, -2px); }
    100%   { transform: translate(0, 0px); }   
}
@keyframes floating-4 {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(-1px, -2px); }
    100%   { transform: translate(0, 0px); }   
}
@keyframes floating-5 {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(-1.5px, -0.5px); }
    100%   { transform: translate(0, 0px); }   
}

@media only screen and (max-width: 1200px){
    .hound{
        margin:0;
        margin:2rem 1rem 0 2rem;
    }
}