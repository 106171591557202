.error-alert{
    font-family: "Tech",sans-serif !important;
    text-transform: capitalize !important;
    color:#eee;
    background-color:rgba(241,80,70,.85);
    margin:1rem;
    font-size:1rem !important;
    width:100%;
}
.error-title{
    color:#eee;
    font-family: "Tech",sans-serif !important;
    text-transform: capitalize !important;
    font-size:1.5rem !important;
}

.error-button{
    font-family: "Primal",sans-serif !important;
    margin-top:1rem !important;
    color:#f9f9f9 !important;
    font-size: .75rem !important;
    background-color: #212121 !important;
}
