
@font-face{
    font-family:"Tech";
    src:url("../../../../assets/webfonts/ShareTechMono-Regular.ttf");
    font-weight:400;
    font-display:swap;
}
.Terminal-window {
    font-family: 'Tech', monospace;
    font-size: .75rem;
    color:#00acc1;
    position: relative;
    width: 100%;
    cursor:default;
  }
  
  .Terminal-window-white {
    background: #fff;
    border-style: solid;
    border-color: transparent;
  }
  
  .Terminal-term {
    width: 100%;
    min-height: 170px;
  }
  
  .Terminal-term-code {
    height: 100%;
  }
  
  .Terminal-header {
    position: absolute;
    width: 100%;
    top: 18px;
  }
  
  .Terminal-body {
    width: 100%;
    height: 100%;
    margin-top: 45px;
  }
  
  .Terminal-body-animated {
    position: absolute;
  }
  
  .Terminal-console {
    font-size: .75rem;
    font-family: 'Tech', monospace;
    line-height: 24px;
    color: #e0e0e0;
    margin: 0 0 1rem 0;
  }
  
  .Terminal-console-code {
    margin: 40px 16px;
  }
  
  .Terminal-console-white {
    color: #000;
  }
  
  .Terminal-btn {
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    display:none;
  }
  
  .Terminal-btn-close {
    background-color: rgb(255, 95, 86);
    left: 13px;
  }
  
  .Terminal-btn-minimize {
    background-color: rgb(255, 189, 46);
    left: 33px;
  }
  
  .Terminal-btn-maximize {
    background-color: rgb(39, 201, 63);
    left: 53px;
  }
  
  .Terminal-prompt {
    color: #bdbdbd;
  }

  .Terminal-cursor {
    display: inline-block;
    width: 6px;
    height: 15px;
    background: #ec407a;
    vertical-align: middle;
  }
  
  .Terminal-code {
    margin: 0;
    font-family: 'Tech', monospace;
    font-size: .75rem;
    line-height:1rem;
    white-space: pre-wrap;
  }
  
  .Terminal-control-btn {
    position: absolute;
    bottom: 52px;
    right: 8px;
    display: inline-block;
    border-radius: 3px;
    padding: 5px 10px;
    background: transparent;
    color: white;
    border: 2px solid white;
    font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    cursor: pointer;
    display:none;
  }
  
  .Terminal-control-btn-white {
    color: black;
    border: 2px solid black;
  }
  

  .Typist .Cursor{
    display:inline-block;
    color:#ec407a;
  }
  .Typist .Cursor--blinking {
      opacity: 1;
      animation: blink 2s linear infinite; 
  }



  @keyframes blink {
    0% {
      opacity: 1; }
    50% {
      opacity: 0; }
    100% {
      opacity: 1; } 
  }