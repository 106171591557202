@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;900&display=swap');
@font-face{
    font-family:"Mechsuit";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/mechsuit.otf");
    src:url('https://chainhounds.s3.amazonaws.com/webfonts/mechsuit.woff') format('woff');
    font-weight:400;
    font-display:swap;
}
@font-face{
    font-family:"Garde";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/garde.otf");
    src:url('https://chainhounds.s3.amazonaws.com/webfonts/garde.woff') format('woff');
    font-weight:400;
    font-display:swap;
}
@font-face{
    font-family:"Primal";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/primal.otf");
    src:url('https://chainhounds.s3.amazonaws.com/webfonts/primal.woff') format('woff');
    font-weight:400;
    font-display:swap;
}
@font-face{
    font-family:"Mandatory";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/Mandatory-font.otf");
    font-weight:400;
    font-display:swap;
}
body{
    font-family: 'Orbitron', sans-serif !important;
    background-color:#e0e0e0 !important;
    color:#212121 !important;
    font-size:1rem !important;
    line-height:1.75rem !important;
    box-sizing: border-box;
    display: flex;
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
}

.bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url(../images/noise-transparent.png) repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation .2s infinite;
    opacity: .9;
    visibility: visible;
    z-index: -1;
  }
  
  @keyframes bg-animation {
      0% { transform: translate(0,0) }
      10% { transform: translate(-5%,-5%) }
      20% { transform: translate(-10%,5%) }
      30% { transform: translate(5%,-10%) }
      40% { transform: translate(-5%,15%) }
      50% { transform: translate(-10%,5%) }
      60% { transform: translate(15%,0) }
      70% { transform: translate(0,10%) }
      80% { transform: translate(-15%,0) }
      90% { transform: translate(10%,5%) }
      100% { transform: translate(5%,0) }
  }


::-webkit-scrollbar{
    width: 0.4em;
}
::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(255,255,255,.1);
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,.1);
}
::-webkit-scrollbar-thumb{
    background-color: rgb(158,158,158);
    outline: 1px solid rgb(158,158,158);
    border-radius: .25rem;
}


#root{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    width:100%;
    flex: 1 0 auto;
    background-size: 100%;
    z-index: 999;
    overflow-x: hidden;
}    

.MuiButton-root{
    text-transform: unset;
}

a{
    color:#0097a7;
    transition: color .2s ease-in;
}
a:hover{
    color:#00bcd4;
}

.MuiButton-containedPrimary{
    background-color:#00bcd4;
    color:#000;
}

.MuiButton-containedPrimary:hover{
    background-color:#0097a7;
}

@media only screen and (min-width: 1200px){
    main{
        padding-right: 400px; /* width of terminal drawer*/
    }
    body{
        background-image: url(../images/world.svg);
    }    
}

.content-box{
    border-radius: 0 !important;
    font-size:1rem;
}