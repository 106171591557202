.success-alert{
    font-family: "PhSequential",sans-serif !important;
    text-transform: capitalize !important;
    color:#f9f9f9;
    background-color:#01FE03;
    border:2px solid #f9f9f9;
    margin:1rem;
    font-size:1.25rem !important;
    width:100%;
}
.success-title{
    color:#f9f9f9;
    font-size:1.75rem !important;
}

.success-button{
    margin-top:1rem !important;
    color:#f9f9f9 !important;
    border:2px solid #f9f9f9 !important;
    font-size: 1rem !important;
    background-color: #16151A !important;
}

.success-button:hover{
    border:2px solid #f9f9f9;
}