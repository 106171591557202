.a{
    font-family: 'Tech',sans-serif !important;
    font-size:.85rem;
    color:#e0e0e0;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
}
.a:hover{
    color:#fff;
    text-decoration: underline;
}
.h2{
    font-size: 1.25rem;
    margin-bottom:0;
}